<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <span>组织架构：</span>
        <tchtree v-model="class_id" @change="getList"></tchtree>

        <el-input placeholder="学生姓名" v-model="searchForm.stu_name" size="small" class="input-with-select"
          style="width:200px" clearable></el-input>

        <el-date-picker v-model="date" size="small" class="input-with-select" style="width:200px;margin-left:10px"
          type="month" placeholder="选择月" value-format="yyyy-MM"></el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="()=>{page.current_page = 1;getList()}">搜索</el-button>
        <!-- 
        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary" plain
          @click="saveJh">添加单位考评意见</el-button> -->
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px;"
                   size="small" type="primary" @click="tongbuJava">
          迁移老版本
        </el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="100" label="序号"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="300"></el-table-column>
      <el-table-column prop="year" label="年份" width="200"></el-table-column>
      <el-table-column prop="month" label="月份" width="200"></el-table-column>
      <el-table-column prop="cdate" label="创建时间" min-width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="primary" size="mini" @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag v-if="!$store.getters.isGradeLeader(scope.row.class_id)" class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog title="单位考评意见表" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" custom-class="cus_dialog" width="800px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ EditItem.stu_name }} {{ EditItem.year }}-{{ EditItem.month }} 单位考评意见详情</span>
      </div>
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">

          <el-form-item label="学生姓名：">{{ EditItem.stu_name }}
          </el-form-item>

          <el-form-item label="年月：">{{ EditItem.year }}-{{ EditItem.month }}
          </el-form-item>

          <el-form-item label="考评表拍照：">
            <el-image style="width: 250px; height: 353px" :src="EditItem.url" :preview-src-list="[EditItem.url]"
              img="cover"></el-image>
          </el-form-item>
        </el-form>
      </div>
      <div class="" slot="footer">
        <el-button size="small" type="primary" plain @click="dialogshow = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import tchtree from '../com/tchTree.vue'

export default {
  components: {
    formdialog, tchtree
  },
  data() {
    return {
      dialogshow: false,
      date: "",
      searchForm: {
        stu_name: "",
        year: "",
        month: ""
      },
      EditItem: {
        is_draft: 1,
        title1: "",
        id: 0,
        stu_id: 0,
        year: "",
        month: "",
        url: []
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      class_id: null,

    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let arr = (this.date && this.date.length > 0) ? this.date.split("-") : '';
      this.$http
        .post("/api/unit_evaluation_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_name: this.searchForm.stu_name,
          month: arr != "" ? Number(arr[1]) : "",
          year: arr != "" ? Number(arr[0]) : "",
          class_ids: this.class_id || ''
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/unit_evaluation_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.EditItem = {
        is_draft: 1,
        title1: "添加单位考评意见",
        id: 0,
        stu_id: 0,
        year: "",
        month: "",
        url: []
      };
      this.dialogshow = true;
    },
    viewInfo(row) {
      row.month = row.month.toString().padStart(2, '0')
      this.EditItem = { ...row };
      this.dialogshow = true;
      console.log(192, row)
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_unit_evaluate", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
