<template>
  <div v-if="EditItem">
    <el-dialog title="单位考评意见详情" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="closeDialog" custom-class="cus_dialog" width="800px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">单位考评意见详情</span>
      </div>
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="学号">
                <el-select v-model="EditItem.stu_num" :disabled="dialogxx" filterable remote reserve-keyword
                  placeholder="请输入学号" :remote-method="remoteMethod" :loading="loading" style="width:180px"
                  @change="checkedxz" @focus="focusxz">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="学生姓名">
                <el-input v-model="EditItem.stu_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="月份">
                <el-date-picker v-model="date" style="width:180px" type="month" placeholder="选择月"
                  value-format="yyyy-MM"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="附件">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in EditItem.url" :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

              <div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)">
                <i class="el-icon-remove"></i>
              </div>
            </div>

            <div style="display:inline-block;vertical-align: top;" v-if="EditItem.url.length == 0">
              <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                :on-success="uploadFiles" :show-file-list="false" name="image">
                <div class="imgaddbtn">＋</div>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>

        <el-row>
          <el-col :span="22" style="text-align: right;padding-left: 10px;">
            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogshow: true,
      dialogxx: true,
      disabled: true,
      date: "",
      optionsRow: {
        stu_code: "",
        stu_id: "",
        stu_name: ""
      },
      EditItem: {
        is_draft: 0,
        title1: "",
        id: 0,
        stu_id: 0,
        year: "",
        month: "",
        url: []
      },
      options: [],
      loading: false
    };
  },
  mounted() {
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        this.disabled = false;
        this.dialogxx = false;
      } else {
        this.date = res.year + "-" + res.month;
        res.url = JSON.parse(res.url);
        this.options = [{ id: res.stu_id, label: res.stu_code }];
        this.disabled = false;
        res.is_draft = 1;
      }
      this.EditItem = res;
    });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.$http
          .post("/api/students_class_info", { stu_code: query })
          .then(res => {
            this.loading = false;
            if (res.data) {
              this.options = [{ value: res.data.id, label: res.data.stu_name + '-' + res.data.stu_code }];
              this.optionsRow.stu_code = res.data.stu_code;
              this.optionsRow.stu_id = res.data.id;
              this.optionsRow.stu_name = res.data.stu_name;
            }
          });
      } else {
        this.options = [];
      }
    },
    checkedxz() {
      this.dialogxx = false;
      this.EditItem.stu_code = this.optionsRow.stu_code;
      this.EditItem.stu_id = this.optionsRow.stu_id;
      this.EditItem.stu_name = this.optionsRow.stu_name;
    },
    focusxz() {
      this.options = [];
      this.optionsRow.stu_code = "";
      this.optionsRow.stu_id = "";
      this.optionsRow.stu_name = "";
    },
    uploadFiles(e) {
      this.EditItem.url.push(e);
    },
    removeImg(idx) {
      this.EditItem.url.splice(idx, 1);
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    submitForm(st) {
      if (!this.EditItem.stu_id) {
        this.$message.error("学生必选!");
        return false;
      }

      if (this.EditItem.url.length == 0) {
        this.$message.error("附件必传!");
        return false;
      }
      if (this.date == "") {
        this.$message.error("月份必选!");
        return false;
      }
      let arr = this.date.split("-");
      this.EditItem.month = Number(arr[1]);
      this.EditItem.year = Number(arr[0]);
      this.EditItem.url = JSON.stringify(this.EditItem.url);
      let data = JSON.parse(JSON.stringify(this.EditItem));
      this.$http.post("/api/unit_evaluation_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    }
  }
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
